<template>
    <div>
        <el-dialog
        :title="isFlag ? '群组重命名' : '创建群组' "
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="群组名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入群组名称" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleEvent('ruleForm')">取 消</el-button>
                <el-button size="small" type="primary" @click="onSureEvent">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        viewerKey:{
            type: String,
            default: 'group'
        }
    },
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
            },
            isFlag: false,
            infos: {}
        };
    },
    methods: {
        init(isFlag, infos){
            this.isFlag = isFlag;
            this.infos = infos;
            if(isFlag) {
                this.ruleForm.name = infos.name;
            } else {
                this.ruleForm = {
                    name: ''
                }
            }
        },
        onSureEvent(){
             this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.isFlag) {
                        this.$store.dispatch("newGroup/renameGroup", {
                            vkey: this.viewerKey, 
                            query:{
                                id: this.infos.id,
                                name: this.ruleForm.name,
                            }          
                        }).then(state =>{
                            if(state.code==0){
                                this.$message.success('修改成功');
                                this.dialogVisible = false;
                                this.$emit('refreshList');
                            }else{
                                this.$message.error(state.message);
                                this.dialogVisible = false;
                            }
                        }).catch(message => {
                            this.$message.error(message.message);
                            this.dialogVisible = false;
                        })
                    } else {
                        let query = {
                            name: this.ruleForm.name,
                            type: 1
                        }
                        this.$store.dispatch("newOrg/createDepartment", query).then(state =>{
                            if(state.code==0){
                                this.$message.success('创建成功');
                                this.dialogVisible = false;
                                this.$emit('refreshList');
                            }else{
                                this.$message.error(state.message);
                                this.dialogVisible = false;
                            }
                        }).catch(error => {
                            this.$message.error(error.message);
                            this.dialogVisible = false;
                        })
                    }
                }
             })
        },
        cancleEvent(formName){
            this.$refs[formName].resetFields();
            this.dialogVisible = false;
        },
    }
};
</script>
<style lang="less" scoped>
</style>