<template>
    <el-dialog
    title="添加成员"
    :close-on-click-modal="false" 
    :visible.sync="dialogVisible" @close="canclesClick">
        <el-input v-model="formInline.name" placeholder="请输入手机号/用户名/登录名" @keyup.native.enter="getKeyUpEvent($event)">
            <i slot="suffix" class="el-input__icon el-icon-search seicons" @click="handleClick"></i>
        </el-input>
        <ul class="search-list" v-if="searchResult">
            <li v-for="(item,index) in searchList" :key="index">
                <span>{{item.name}}</span>
                <el-button size="small" @click="sureEvent(item)">添加</el-button>
            </li>
        </ul>

        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="canclesClick">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { usermanagerAPI } from 'liankong-ui-api' 

export default {
    data() {
        return {
            dialogVisible: false,
            formInline: {
                name: '',
                phoneNumber: '',
                pageSize: 30
            },
            isVisible: false,
            searchList: [], // 搜索结果内容
            orgInfos: {}, // 部门添加成员的信息
            variable: '',
            getLists: {} // 组织添加成员的信息
        };
    },
    computed: {
        names(){
            return this.formInline.name
        },
        // 是否搜索结果
        searchResult(){
            return this.formInline.name && this.searchList.length > 0
        }
    },
    watch: {
        // 输入变动
        names(value){
            if(value && value.length > 0){
                this.getSendList()
            }else{
                this.searchList = []
            }
        }
    },
    methods: {
        init(orgInfo, variable) {
            this.variable = variable;
            if(variable == 'deps') {
                this.orgInfos = orgInfo;
            } else if(variable == 'orgs' || variable == 'deporgs') {
                this.getLists = orgInfo;
            }
        },
        // 键盘事件
        getKeyUpEvent(event){
            if(this.formInline.name) {
                if(event.keyCode == 13) { // 回车
                   this.getSendList()
                }
            }
        },
        getSendList(){
            this.formInline.phoneNumber = this.formInline.name;
            usermanagerAPI.getUserList(this.formInline).then(res=>{
                if(res.code == '0') {
                    this.searchList = res.data.records;

                    if(this.searchList.length == 0) {
                        this.$message.error('未搜索到用户');
                    }

                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 搜索按钮
        handleClick(){
            if(this.formInline.name == '') {
                this.$message.error('请输入手机号/用户名/登录名');
                return false;
            }
            this.getSendList()
        },
        // 确定
        sureEvent(item){
            if(this.variable == 'deps'){
                this.$store.dispatch('newGroup/addGroupMember',{
                    vkey: 'group',
                    query:{
                        id: this.orgInfos.id,
                        userId: item.id
                    }
                }).then(state => {
                    if(state.code==0){
                        this.$message.success('添加成功')
                        this.$emit('onLoadList')
                        this.canclesClick()
                    }else{
                        this.$message.error(state.message)
                        this.dialogVisible = false;
                    }
                }).catch(state => {
                    this.$message.error(state.message);
                    this.dialogVisible = false;
                })
            } else if(this.variable == 'orgs' || this.variable =='deporgs') {
                this.$store.dispatch('newOrg/addOrgMember',{
                    id: this.getLists.id,
                    userId: item.id
                }).then(state => {
                    if(state.code == '0') {
                        this.$message.success('添加成功')
                        this.canclesClick();
                        if(this.variable == 'orgs') {
                            this.$emit('onLoadNOrgList')
                        } else if(this.variable =='deporgs'){
                            this.$emit('onLoadNOrgList')
                        }
                    } else{
                        this.$message.error(state.message);
                        this.dialogVisible = false;
                    }
                }).catch(state => {
                    this.$message.error(state.message)
                    this.dialogVisible = false;
                })
            }
            
        },
        // 取消
        canclesClick(){
            this.dialogVisible = false;
            this.isVisible = false;
            this.formInline = {
                name: '',
                phoneNumber: '',
                pageSize: 30
            }
            this.searchList = [];
        }
    }
};
</script>
<style lang="less" scoped>
.search-list {
    height: 193px;
    overflow: auto;
    margin-top: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 0;
    cursor: pointer;
    li{
        list-style: none;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    li:hover {
        background: #f2f2f2;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
.seicons {
    cursor: pointer;
    font-size: 20px;
    padding-right: 10px;
}
</style>