<template>
    <div class="group-wrap">
        <el-table
        v-if='tableData.length != 0'
        size="small"
        height="calc(100vh - 265px)"
        :data="tableData"
        style="width: 100%;">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60">
            </el-table-column>
            <el-table-column
                prop="username"
                label="成员名称"
                align="center">
            </el-table-column>
             <el-table-column
                prop="mobile"
                label="手机号"
                align="center">
            </el-table-column>
            <el-table-column
                label="成员身份"
                align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.userId == itemInfo.owner ? '群主' : '群成员'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="日期"
                align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.joinDate | date}}</span>
                </template>
            </el-table-column>
            <el-table-column
            label="操作"
            align="center"
            >
                <template slot-scope="scope">
                    <el-button  type="text" size="small" v-if="scope.row.userId != itemInfo.owner" @click="removeMenberEvent(scope.row)">删除</el-button>
                    <span v-else>--</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="no-data-box" v-if='tableData.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>

        <el-pagination
        v-if='tableData.length != 0'
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,  prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <AddgroupDialog ref="addGroupDialog" :viewerKey="itemInfo.tip == 'create' ? 'group' : 'join'" @refreshList="refreshList" ></AddgroupDialog>
        <AdduserDialog ref="addUserDialog" @onLoadList="onLoadAllList()"></AdduserDialog>
    </div>
</template>
<script>
import AddgroupDialog from './components/AddGroupDialog.vue'
import AdduserDialog from './components/AddUserDialog.vue'
export default {
    data() {
        return {
            viewerKey: 'member',
            pageNum: 1,
            pageSize: 10,
            name: '',
            itemInfo: {}
        }
    },
    inject: ['refreshListMyGroup'],
    components: {
        AddgroupDialog,
        AdduserDialog
    },
    computed: {
        tableData(){
            return this.$store.state.newOrg.views[this.viewerKey].list || []
        },
        total() {
            return this.$store.state.newOrg.views[this.viewerKey].paging.total;
        }
    },
    mounted(){
        this.$ebus.$on('handleClickMyGroupTreeLeft', ({item,ind})=>{
            this.itemInfo = item;
            this.name = '';
            this.onLoadAllList()
        })
        this.$ebus.$on('addGroupBtn', flag=>{
            this.addGroupBtn(flag)
        })
        this.$ebus.$on('removeGroupBtn', val=>{
            this.removeGroupBtn()
        })
        this.$ebus.$on('addMemberBtn',val=>{
            this.addMemberBtn();
        })
        this.$ebus.$on('handleAuditSearchBtn', formInline=>{
            this.name = formInline.menber
            this.onLoadAllList()
        })
        this.$ebus.$on('disbandTheGroupBtn', val=>{
            this.disbandTheGroupBtn()
        })
    },
    methods: {
        // 查看成员
        onLoadAllList() {
            if(this.itemInfo.id){
                this.$store.dispatch('newOrg/getGroupPeopleList',{
                    reset:true,
                    query:{
                        name: this.name,
                        id: this.itemInfo.id,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize
                    }
                })
            }
           
        },
        // 创建、重命名群组
        addGroupBtn(flag) {
            this.$refs.addGroupDialog.dialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.addGroupDialog.init(flag, this.itemInfo)
            })
        },
        // 创建群组回调
        refreshList(){
            this.refreshListMyGroup()
        },
        // 删除群组
        removeGroupBtn() {
            this.$store.dispatch('newGroup/remove', {
                vkey: this.viewerKey,
                query: {
                    id: this.itemInfo.id
                }
            }).then(state => {
                if(state.code==0){
                    this.$message.success("删除成功");
                    this.refreshList();
                }else{
                    this.$message.error("删除失败")
                }
            }).catch(state => {
                this.$message.error(state.message);
            })
        },
        // 添加成员
        addMemberBtn() {
            this.$refs.addUserDialog.dialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.addUserDialog.init(this.itemInfo,'deps')
            })
        },
        // 删除成员
        removeMenberEvent(row) {
            this.$store.dispatch('newGroup/removeGroupMember',{
                vkey: this.viewerKey,
                query:{
                    id: this.itemInfo.id,
                    userId: row.userId
                }
            }).then(state => {
                if(state.code==0){
                    this.$message.success("删除成功");
                    this.onLoadAllList();
                }else{
                    this.$message.error("删除失败");
                }
                
            }).catch(state => {
                this.$message.error(state.message);
            })
        },
        // 分页
        handleCurrentChange(num) {
            this.pageNum = num;
            this.onLoadAllList();
        },
        // 解散本群
        disbandTheGroupBtn() {
            this.$store.dispatch('newGroup/remove', {
                vkey: this.viewerKey,
                query: {
                    id: this.itemInfo.id
                }
            }).then(state => {
                if(state.code==0){
                    this.$message.success("解散成功");
                    this.refreshList();
                }else{
                    this.$message.error("解散失败")
                }
            }).catch(state => {
                this.$message.error(state.message);
            })
        }
    },
    beforeDestroy() {
        this.$ebus.$off('handleClickMyGroupTreeLeft');
        this.$ebus.$off('addGroupBtn');
        this.$ebus.$off('removeGroupBtn');
        this.$ebus.$off('addMemberBtn');
        this.$ebus.$off('handleAuditSearchBtn');
        this.$ebus.$off('disbandTheGroupBtn');
    }
}
</script>
<style lang="less" scoped>
.group-wrap {
    /deep/ .el-table {
        .el-table__header-wrapper {
            .has-gutter {
                tr th{
                    border:none;
                    background: #F2F2F2;
                    color: #666;
                }
            }
        }
        .el-table__body-wrapper {
            tr td {
                border:none;
                padding: 12px 0;
            }
        }
        &::before {
            height: 0px;
        }
    }
   .pagebox {
        margin-top: 20px;
        text-align: left;
   }
}
</style>